<template>
    <div class="inner-container">
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="goodsData"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >   
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAllGoods ? 'всех' : 'выбранных'} записей`)"/>
                    </div>
                </template>
                <template #top-row="data">
                    <td></td>
                    <td colspan="3" class="td-numbering table-success text-center">1</td>
                    <td class="td-numbering table-danger text-center">2</td>
                    <td class="td-numbering table-info text-center">3</td>
                    <td class="td-numbering table-primary text-center">4</td>
                    <td class="td-numbering table-warning text-center">5</td>
                    <td class="td-numbering table-success text-center">6</td>
                    <td class="td-numbering table-danger text-center">7</td>
                    <td class="td-numbering table-info text-center">8</td>
                    <td class="td-numbering table-primary text-center">9</td>
                    <td class="td-numbering text-center"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAllGoods" 
                            @change="e => $emit('setIsAllDelete', e, goodsData)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                
                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAllGoods = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(good_name)="data">
                    <div class="form-03149-multiselect-group">
                        <multiselect
                            v-b-popover.hover.topright="currGoodName ? currGoodName.name : null"
                            v-if="variantAttribute && data.item.isNew"
                            v-model="currGoodName"
                            style="width: 270px"
                            label="name"
                            placeholder="Введите наименование"
                            :options="ensTruNamePrt"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            :internal-search="false"
                            @blur="() => ensTruNamePrt.splice(0)"
                            @search-change="(e) => $emit('searchName', e)"
                            @input="setCurrGoodDesc(data.item)"
                        >
                        <span slot="noResult">
                            Наименование не найдено
                        </span>
                        <span slot="noOptions">
                            Начните вводить наименование для поиска
                        </span>
                        </multiselect>
                        <div v-else :id="`ens-item-name-${data.item.id}`" v-bind:class="{'ens-item-non-active': !data.item.esnActive}">{{ data.item.name }}</div>
                        <b-spinner v-if="data.item.isNew && parNameLoading" small label="Small Spinner"></b-spinner>
                        <b-popover v-if="!data.item.esnActive" placement="topright" :target="`ens-item-name-${data.item.id}`" triggers="hover focus">
                            Код не активен  
                        </b-popover>
                    </div>
                    
                </template>
                <template #cell(shrt_descr)="data">
                    <b-spinner class="form-03149-spinner" v-if="data.item.isNew && descIsLoading" small label="Small Spinner"></b-spinner>
                    <multiselect
                        v-b-popover.hover.topright="data.item.currGoodDesc ? data.item.currGoodDesc.detail : null"
                        v-if="variantAttribute && data.item.ensTruName && data.item.ensTruName.length > 1"
                        v-model="data.item.currGoodDesc"
                        style="width: 270px"
                        label="detail"
                        placeholder="Выберите характеристику"
                        :options="data.item.ensTruName"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="descSelectedNameChanged(data.item)"
                    >
                    <span slot="noOptions">
                        Сначала необходимо выбрать наименование
                    </span>
                    </multiselect>

                    <div v-else>{{ data.item.shrDescr }}</div>
                </template>
                <template #cell(additional_descr)="data">
                    <b-form-textarea v-if="variantAttribute && data.item.enstru_code"
                        style="min-height: 100px"
                        v-model.trim="data.item.add_detail"
                        @change="$emit('onDataChanged', data.item)"
                        :formatter="formatText"
                    ></b-form-textarea>
                    <div v-else>{{ data.item.add_detail }}</div>
                </template>
                <template #cell(unit)="data">
                    <multiselect
                        v-if="variantAttribute && data.item.uom && data.item.uom.length > 1"
                        style="max-width: 130px"
                        :value="data.item.uom.filter(u => u.code === data.item.unit_code)[0]"
                        @input="v => {currUnit = v; $set(data.item, 'unit_code', v.code); $emit('onDataChanged', data.item)}"
                        label="name"
                        :options="data.item.uom"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                    >
                    </multiselect>
                    <div v-else>{{ getUnitName(data.item.unit_code) }}</div>
                </template>
                <template #cell(fact_cost)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.fact_cost"
                                  @change="v => {data.item.fact_cost = v; $emit('onDataChanged', data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'fact_cost', data.item.fact_cost, 5)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.factcostPassVal">Должно быть > 0!</p>
                </template>
                <template #cell(area)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.area"
                                  @change="v => {data.item.area = v; $emit('onDataChanged', data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'area', data.item.area, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.areaPassVal">Должно быть > 0!</p>
                </template>
                <template #cell(months)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.months"
                                  @change="v => {data.item.months = v; $emit('onDataChanged', data.item)}"
                                  @keyup="(e) => $emit('checkNumber', e, 0, 12)"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'months', data.item.months, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.monthsPassVal">Должно быть > 0!</p>
                </template>
                <template #cell(amount)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(price)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.price"
                                  @change="v => {data.item.price = v; $emit('onDataChanged', data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'price', data.item.price, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.pricePassVal">Должно быть > 0!</p>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                
                <template #cell(more)="data">
                    <!-- <b-button :disabled="goodsAreSaving" v-if="variantAttribute" @click="deleteItem('данной записи', data.item, data.index)"> -->
                    <i class="icon icon-clear table-remove" :disabled="goodsAreSaving" v-if="variantAttribute" @click="deleteItem('данной записи', data.item, data.index)"></i>
                    <!-- </b-button> -->
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.file_hash ? data.item.file_hash.split(';').length : 0}})</span>
                </template>
                <template #bottom-row="data">
                    <td class="text-left" colspan="5">ИТОГО</td>
                    <td class="text-right">{{ String(totalFactCost).replace('.', ',') }}</td>
                    <td class="text-right">{{ $n(totalArea) }}</td>
                    <td class="text-right">{{ $n(totalMonths) }}</td>
                    <td class="text-right">{{ $n(totalAmount) }}</td>
                    <td class="text-right">{{ $n(totalPrice) }}</td>
                    <td class="text-right">{{ $n(totalTotal) }}</td>
                    
                    <td colspan="2"></td>
                </template>
                
            </b-table>

            <modal-files-management 
                ref="modalFilesManagement"
                :allFiles="allFiles"
                :variantAttribute="variantAttribute"
                @showFileUploadModal="showFileUploadModal"
                @setNewFileHash="setNewFileHash"
                @triggDownloadFile="triggDownloadFile"
            ></modal-files-management>
            
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ModalFilesManagement from './modal-files-management.vue';


export default {
    name: 'FormTemplate5',
    components: { ModalFilesManagement },
    props: {
        goodsData: Array,
        initialGoodsData: Object,
        ensTruDict: Array,
        variantAttribute: Boolean,
        currCategory: Object,
        lang: String,
        deleteGood: Function,
        ifAllFieldsFilled: Function,
        getUnitName: Function,
        ensTruNamePrt: Array,
        ensTruName: Array,
        currUnits: Array,
        loadCurUnits: Function,
        getEnstruByName: Function,
        showFileUploadModal: Function,
        triggDownloadFile: Function,
        parNameLoading: Boolean,
        descIsLoading: Boolean,
        allFiles: Array,
        formCode: String,
        goodsAreSaving: Boolean
    },

    watch: {
        goodsData: {
            handler(newValue, oldValue) {
                this.calcTotals();
            },
            deep: true
        }
    },



    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'good_name',
                    label: 'Наименование'
                },
                {
                    key: 'shrt_descr',
                    label: 'Краткая характеристика'
                },
                {
                    key: 'additional_descr',
                    label: 'Дополнительная характеристика'
                },
                {
                    key: 'unit',
                    label: 'Единица измерения'
                },
                {   key: 'fact_cost',
                    label: 'Фактический расход топлива за прошлый год в месяц на 1 кв.м' },
                {   key: 'area',
                    label: 'Отапливаемая площадь' },
                {   key: 'months',
                    label: 'Продолжительность отопительного сезона (месяцев)' },
                {   key: 'amount',
                    label: 'Количество (гр.3 х гр.4 х гр.5)' },
                {   key: 'price',
                    label: 'Средняя стоимость за единицу, тенге' },
                {   key: 'total',
                    label: 'Общая стоимость, тыс.тенге (гр.6 х гр.7)/1000' },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                },
                
            ],
            dataTypeFilter: null,
            variantName: null,
            formFilter: null,
            budgetForm: [],
            header: null,
            dict: [],
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            currUnit: null,
            currGoodName: null,
            totalFactCost: 0,
            totalArea: 0,
            totalMonths: 0,
            totalAmount: 0,
            totalTotal: 0,
            totalTotalSaved: 0,
            totalPrice: 0,
            currGoodDesc: null,
            selectAllGoods: false
        };
    },

    created() {
        
    },

    methods: {
        addItem() {
            // проверяем все ли поля заполнены            
            if (!this.ifAllFieldsFilled()) return;
            this.currGoodName = null;     
            const item = { id: new Date() * -1 };
            this.$set(item, 'isNew', true);
            this.$set(item, 'rowToSave', true);
            this.$set(item, 'factcostPassVal', false);
            this.$set(item, 'areaPassVal', false);
            this.$set(item, 'monthsPassVal', false);
            this.$set(item, 'pricePassVal', false);
            this.$set(item, 'currGoodDesc', null);
            this.$set(item, 'esnActive', true);
            this.$set(item, 'fact_cost', 0);
            this.$set(item, 'area', 0);
            this.$set(item, 'months', 0);
            this.$set(item, 'amount', 0);
            this.$set(item, 'price', 0);
            this.$set(item, 'total', 0);
            this.$set(item, 'esnActive', true);
            this.$set(item, 'itemToDelete', false);
            this.goodsData.push(item);
            this.ensTruDict.splice(0);
        }, // добавление записи в таблицу

        async setCurrGoodDesc(item) {
            await this.getEnstruByName(this.currGoodName.name);
            process.nextTick(() => {
                if (this.ensTruName.length) {
                    const relativeDecrIdx = this.ensTruName.findIndex(itm => itm.code === this.currGoodName.code);
                    this.$set(item, 'currGoodDesc', this.ensTruName[relativeDecrIdx !== -1 ? relativeDecrIdx : 0]);
                    this.$set(item, 'ensTruName', this.ensTruName);
                    this.descSelectedNameChanged(item);
                };
            })
        },

        makeToast(variant = null, title, msg) {
            this.$bvToast.toast(msg, {
            title: title,
            variant: variant,
            solid: true
            })
        },
        
        changedUnit(item) {
            //  this.$set(item, 'unit_code', this.currUnit.code);
        },

        async descSelectedNameChanged(item) {
            await this.loadCurUnits(item.currGoodDesc.code)
            process.nextTick(() => {
                this.$set(item, 'enstru_code', item.currGoodDesc.code);
                const uom = [];
                this.currUnits.forEach(u => {
                    const unitName = this.getUnitName(u.unit_code);
                    uom.push({name: unitName, code: u.unit_code});
                })
                if (uom.length) {
                    this.currUnit = uom[0];
                } else this.currUnit = [];
                this.$set(item, 'unit_code', this.currUnit.code);
                this.$set(item, 'uom', uom);
                this.$set(item, 'shrDescr', item.currGoodDesc['detail_'+this.lang]);
                this.$set(item, 'name', item.currGoodDesc['name_'+this.lang]);
                this.$emit('onDataChanged', item);
            });
        },

        formatText(text) {
            if (text.length > 254) {
                return text.slice(0, 254);
            } else {
                return text;
            }
        },

        inputFixed(item, field, value, digit) {
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? parseFloat(parseFloat(value).toFixed(digit)) : 0;
                this.$set(item, field, newVal);
                if (['fact_cost', 'area', 'months'].includes(field)) {
                    this.$set(item, 'amount', parseFloat((parseFloat(item.fact_cost) * parseFloat(item.area) * parseFloat(item.months)).toFixed(2)));
                };
                if (['fact_cost', 'area', 'months', 'price'].includes(field)) {
                    this.$set(item, 'total', parseFloat((parseFloat(item.amount) * parseFloat(item.price) / 1000).toFixed(3)));
                };
            })
        }, // форматирует введенное значение до digit цифр после запятой + расчет вычислемых полей

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        deleteItem(msg, row = false, index = false) {
            if (!this.variantAttribute || (!row && this.goodsData.findIndex(itm => itm.itemToDelete) === -1)) return;
            let deletingRow = null;
            this.$bvModal.msgBoxConfirm(
                `Подтвердите удаление ${msg}...`,
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            if (!row.id && row.rowToSave) this.goodsData.splice(index, 1); 
                            else {
                                deletingRow = this.goodsData.splice(index, 1);
                                this.calcTotals();
                                this.deleteGood([row]);
                            }
                        } else {
                            const existingRowsToDel = [];
                            let totalDeleting = 0;
                            for (let i = this.goodsData.length - 1; i >=0; i--) {
                                if (this.goodsData[i].itemToDelete) {
                                    const rowId = this.goodsData[i].id;
                                    if (rowId >= 0) {
                                        existingRowsToDel.push(rowId);
                                        const initialRow = this.initialGoodsData[rowId]; 
                                        const curTotal = initialRow.total;
                                        totalDeleting += curTotal || 0;
                                    };
                                    this.goodsData.splice(i, 1);
                                };
                            };
                            this.calcTotals();
                            if (!existingRowsToDel.length) { 
                                this.selectAllGoods = false;
                            } else {
                                const roundedTotalDeleting = totalDeleting.toFixed(3);
                                this.$emit('deleteSeveralGoods', existingRowsToDel, roundedTotalDeleting);
                            }
                        }
                    }
                })
                .catch(error => {
                    if (deletingRow) this.goodsData = this.goodsData.push(deletingRow).sort((a, b) => a.id - b.id);
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаление записи

        calcTotals() {
            const rowsAmoutn = this.goodsData.length > 0 ? this.goodsData.length : 1;
            let sumFactCost = 0;
            let sumArea = 0;
            let sumMonths = 0;
            let sumAmount = 0;
            let sumTotal = 0;
            let sumTotalSaved = 0;
            for (const row of this.goodsData) {
                const factCost = row.fact_cost ? row.fact_cost : 0;
                sumFactCost += factCost;
                const area = row.area ? row.area : 0;
                sumArea += area;
                const months = row.months ? row.months : 0;
                sumMonths += months;
                const amount = row.amount ? row.amount : 0;
                sumAmount += amount;
                const total = row.total ? row.total : 0;
                sumTotal += total;
                if (row.id > 0) {
                    sumTotalSaved += total;
                }
            };
           
            this.totalFactCost = parseFloat(parseFloat(sumFactCost).toFixed(5));
            this.totalArea = parseFloat(parseFloat(sumArea / rowsAmoutn).toFixed(2));
            this.totalMonths = parseFloat(parseFloat(sumMonths / rowsAmoutn).toFixed(2));
            this.totalAmount = parseFloat(parseFloat(sumAmount).toFixed(2));
            this.totalTotal = parseFloat(parseFloat(sumTotal).toFixed(3));
            this.totalPrice = this.totalTotal > 0 ? parseFloat(parseFloat(this.totalTotal * 1000 / this.totalAmount).toFixed(2)) : 0;
            this.totalTotalSaved  = parseFloat(parseFloat(sumTotalSaved).toFixed(3));
        }, 

        onFilesClick(item) {
            this.$refs.modalFilesManagement.showModal(item);
        },

        setNewFileHash(id, codes) {
            for (const good of this.goodsData) {
                if (good.id === id) {
                    this.$set(good, 'file_hash', codes);
                    this.$emit('onDataChanged', good);
                    break;
                }
            }
        },

        getTotal() {
            return {'totalAll': this.totalTotal, 'totalSaved': this.totalTotalSaved};
        }, // передача тотала данной категории родителю
    },
    
}
</script>

<style scoped>
.form-03149-multiselect-group {
    display: flex;
}

.form-03149-multiselect-group .spinner-border {
    position: relative;
    right: 50px;
    opacity: 0.3;
    margin: auto;
    color: #7A87A0;
    z-index: 999;
}

.form-03149-spinner {
    display: block;
    margin: auto;
    color: #7A87A0;
}

.goods-form-val-msg {
    font-size: 0.5rem;
    font-style: italic;
    color: red;
}

.ens-item-non-active {
    color: #6087a099;
}

</style>